import { useRequest } from '@/composables/useRequest';
import { ApiMethod } from '@/enums/api.enum';
import type { TranslationRequest } from '@/types/translation/translation.request';
import type { TranslationResponse } from '@/types/translation/translation.response';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427843485
export const translateApi = async (
  request: TranslationRequest
): Promise<TranslationResponse | undefined> => {
  const { sourceLang, targetLangs, contents, formats } = request;
  const res = await useRequest<TranslationResponse>('studio/v1/translate', {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang'),
      'Content-Type': 'application/json'
    },
    method: ApiMethod.Post,
    data: camelToSnake({
      sourceLang,
      targetLangs,
      contents,
      formats
    }),
    showLoading: false
  });

  return res?.data;
};
